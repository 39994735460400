

<div *ngIf="!loader">

  


  <table class="table cart-table table-bordered table-responsive-xs">
    
    
        <tr>
            <td style="width: 100px;">
               
                    <img 

        
        class="img-fluid lazy-loading" 
        [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'" 
        [lazyLoad]="ImageSrc ? ImageSrc : product.images[0].src" 
        alt="{{ product.images[0].alt }}"
        style="height: 60px;"
        />
                
            </td>
            <!-- <td style=" width: 200px; text-align:  left  !important; font-size: 14px;">
                Item Code: {{ product?.id | titlecase }}
               
            </td> -->
            <td style=" width: 300px; text-align:  left  !important; font-size: 14px;">
                {{ product.title | titlecase }}
               
            </td>
            <!-- <td style=" width: 300px; text-align:  left  !important; font-size: 14px;">

            MOQ {{ product?.moq }} cases increased by {{ product?.mo }} cases
            </td> -->
            <td style=" width: 100px; text-align: left !important;">
                
                {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
                
            </td>
            <td style="text-align: center !important;">
                <div class="qty-box">
                    <div class="input-group">
                        <span class="input-group-prepend">
                             <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrementQuantity(product,product.mo)">
                                <i class="ti-angle-left"></i>
                            </button> 
                        </span>
                        <input type="number" name="quantity" class="form-control input-number" (focusout)="setqty(product,quantity)" name="quantity" [(ngModel)]="quantity">
                        <span class="input-group-prepend">
                             <button type="button" class="btn quantity-right-plus"  data-type="plus" (click)="incrementQuantity(product,product.mo)">
                                <i class="ti-angle-right"></i>
                            </button> 
                        </span>
                    </div>
                </div>
            </td>
            <!-- <td>
                <a href="javascript:void(0)" (click)="removeItem(product)" class="icon">
                <i class="ti-close"></i>
            </a>
            </td> -->
            <td>
                <h2 class="td-color" >
                    {{ ((product.price | discount:product) * product.quantity) * product?.price | currency:currency.currency:'symbol' }}
                </h2>
            </td>
        </tr>
    
</table>
</div>