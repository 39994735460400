import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { GlobalobjectsService } from "../../globalobjects.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(
    private GlobalObjests: GlobalobjectsService,
    private http: HttpClient,
    private router: Router
  ) {}

  readonly getcustomer =
    this.GlobalObjests.APIURL + "BCCustomer/GetCustomerByNo";

  readonly savecustomer =
    this.GlobalObjests.APIURL + "BCCustomer/SaveCustomerWithPassword";

  readonly loginurl = this.GlobalObjests.APIURL + "Authentication/Login";

  readonly getitems =
    this.GlobalObjests.APIURL + "BCAPIs/DownloadCustomerSalesPrice";

  readonly createorder =
    this.GlobalObjests.APIURL + "BC/Sales/CreateSalesOrder";

  readonly addwishlist = this.GlobalObjests.APIURL + "MS/WishList/Add";

  readonly getwishlist =
    this.GlobalObjests.APIURL + "MS/WishList/GetByCustomerID/";

  readonly getfullfillment =
    this.GlobalObjests.APIURL + "BCAPIs/DownloadOrderHistory";

  readonly getunfullfillment =
    this.GlobalObjests.APIURL + "BCAPIs/Getx2xSalesHeader";

  readonly shippingaddress =
    this.GlobalObjests.APIURL + "BCAPIs/GetShippingAddress";

    readonly defaultshippingaddress =
    this.GlobalObjests.APIURL + "BCAPIs/BCAPIs_GetShippingAddressByCustomerShipCode";

  readonly getitemsatt =
    this.GlobalObjests.APIURL + "BCAPIs/GetItemAttributeValues";

  readonly getcredit =
    this.GlobalObjests.APIURL + "BCAPIs/GetCustomerInfoWithCreditLimit";

  readonly getpostalcode = this.GlobalObjests.APIURL + "BCAPIs/GetPostalCodes";

  readonly getSaleOrderLines =
    this.GlobalObjests.APIURL + "BCAPIs/GetSalesOrderLines";

  getCompanyList(): Observable<any> {
    return this.http.get<any>(
      this.GlobalObjests.APIURL + "MS/CountryList/GetAll"
    );
  }
  getCompanyInformation(id: any): Observable<any> {
    return this.http.get<any>(
      this.GlobalObjests.APIURL +
        "MS/Company/Company_GetAllByCountryListID/" +
        id
    );
  }

  public getCustomerByNo(custNo: any, county: any): Observable<any> {
    let body = {
      country: county,
      customerNumber: custNo,
    };
    return this.http.post<any>(this.getcustomer, body);
  }

  public SaveCustomer(data: any): Observable<any> {
    console.log(data);
    return this.http.post<any>(this.savecustomer, data);
  }

  public ValidateUser(data: any): Observable<any> {
    console.log(data);
    return this.http.post<any>(this.loginurl, data);
  }
  
  public ValidateSalesRep(data: any): Observable<any> {
    console.log(data);
    return this.http.post<any>(this.GlobalObjests.APIURL + "Authentication/LoginSalesRep", data);
  }

  GetItmes(data: any) {
    console.log(data);
    return this.http.post<any>(this.getitems, data);
  }

  makeorder(data) {
    return this.http.post(this.createorder, data);
  }

  addtowishlist(data: any) {
    console.log(data);
    return this.http.post(this.addwishlist, data);
  }

  getwishlistdata(custNo: any) {
    return this.http.get(this.getwishlist + custNo);
  }
  deleteWishlistItem(data: any) {
    console.log(data);
    return this.http.get(
      this.GlobalObjests.APIURL + "MS/WishList/Delete/" + data
    );
  }

  getfullfillmentdata(data: any) {
    return this.http.post(this.getfullfillment, data);
  }

  getunfullfillmentdata(data: any) {
    console.log(data);
    return this.http.post(this.getunfullfillment, data);
  }
  getshippingaddress(data: any) {
    return this.http.post(this.shippingaddress, data);
  }
  getdefaultshippingaddress(data: any) {
    return this.http.post(this.defaultshippingaddress, data);
  }
  GetItmesAtt(data: any) {
    console.log(data);
    return this.http.post(this.getitemsatt, data);
  }
  GetItmesAttForOrderSummary(data: any) {
    console.log(data);
    return this.http.post(
      this.GlobalObjests.APIURL + "BCAPIs/GetItemAttributeForOrderSummary",
      data
    );
  }
  getSliderImages() {
    return this.http.get(this.GlobalObjests.APIURL + "MS/Sliders/GetAll");
  }
  getCustomerCredit(data: any): Observable<any> {
    return this.http.post<any>(this.getcredit, data);
  }

  getPostalCode(data: any): Observable<any> {
    console.log(data);
    return this.http.post<any>(this.getpostalcode, data);
  }
  addnewaddress(data: any): Observable<any> {
    console.log(data);
    return this.http.post<any>(
      this.GlobalObjests.APIURL + "BCAPIs/CreateShippingAddress",
      data
    );
  }

  getCompanyInfo(data: any): Observable<any> {
    return this.http.get<any>(
      this.GlobalObjests.APIURL + "MS/Company/Company_GetAllByCountryListID/" + data
    );
  }

  getcountry(data: any): Observable<any> {
    console.log(data);
    return this.http.get<any>(
      this.GlobalObjests.APIURL + "MS/CountryList/GetByID/" + data
    );
  }
  getcountrycode(data: any): Observable<any> {
    console.log(data);
    return this.http.get<any>("https://restcountries.com/v3.1/name/" + data);
  }

  Getpostalcodeg(data: any): Observable<any> {
    console.log(data);
    //return this.http.get<any>('https://api.geonames.org/postalCodeLookupJSON?postalcode='+data.postalcode+'&country='+data.countrycode+'&username=x2xecommerce');

    return this.http.get<any>(
      "https://api.zippopotam.us/" + data.countrycode + "/" + data.postalcode
    );
  }

  CreatePostalCode(data: any): Observable<any> {
    console.log("======create postal======");
    console.log(data);
    return this.http.post<any>(
      this.GlobalObjests.APIURL + "BCAPIs/CreatePostalCode",
      data
    );
  }

  getCustomerLastOrder(data: any): Observable<any> {
    return this.http.post<any>(
      this.GlobalObjests.APIURL + "BC/Sales/GetCustomerLastOrder",
      data
    );
  }

  getSaleOrderLine(data: any): Observable<any> {
    console.log(data);
    return this.http.post<any>(this.getSaleOrderLines, data);
  }

  getOrderHistoryLines(data: any): Observable<any> {
    console.log(data);
    return this.http.post<any>(
      this.GlobalObjests.APIURL + "BCAPIs/GetOrderHistoryLines",
      data
    );
  }
  getPromotionDetails(data: any): Observable<any> {
    return this.http.post<any>(
      this.GlobalObjests.APIURL + "Promotions/GetByCustomer",
      data
    );
  }

  getSaleOrderPDF(body: any): Observable<any> {
    return this.http.post(
      this.GlobalObjests.APIURL + "BCAPIs/GetSalesOrderPDF",
      body,
    );
  }
  getSaleInvoicePDF(body: any): Observable<any> {
    return this.http.post(
      this.GlobalObjests.APIURL + "BCAPIs/GetSalesInvoicePDF",
      body,
    );
  }

  getNewLaunch(data: any): Observable<any> {
    return this.http.get<any>(
      this.GlobalObjests.APIURL + "MS/Items/GetNewLaunchItems/" + data
    );
  }

  GetSetting() {
    return this.http.get(this.GlobalObjests.APIURL + "MS/BusinessInfo/GetAll");
  }

  getshippinginfoext(data: any): Observable<any> {
    return this.http.post<any>(
      this.GlobalObjests.APIURL + "BC/Sales/CustomerShippingInfo",
      data
    );
  }
  // In SettingService
  getSettingsPromise(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.GetSetting().subscribe({
        next: (data: any) => {
          if (data) {
            localStorage.setItem('setting', JSON.stringify(data.data[0]));
            console.log('🚀 > SettingService > getSettingsPromise > data:', data);
            resolve(data.data[0]);
          } else {
            console.log('🚀 > SettingService > getSettingsPromise > data: IS NULL');
            resolve(null);
          }
        },
        error: (error:any ) => {
          console.error('🚀 > SettingService > getSettingsPromise > error:', error);
          console.log('🚀 Error agyaa ha bhaiii...');
          this.router.navigate(['/500']);
          resolve(null);        },
      });
    });
  }
}
